import { css, styled, Button, Box, Cluster, Dropdown, Sidebar, Stack } from '@a1s/ui';
import { format, parseISO } from 'date-fns';

import React, { ComponentProps, useEffect, useMemo, useState } from 'react';
import Dropzone, { DropFilesEventHandler } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Dispositions, SearchResultRow } from '../../types';
import { Checkbox } from '../Checkbox';

import { ReactComponent as CloudSVG } from './cloud-up.svg';

import { useReportedAlerts, useReportFalseNegativeMutation } from 'screens/Search/lib/hooks';
import usePreviewsMutation, { PreviewType } from 'screens/Search/lib/hooks/usePreviewsMutation';
import useRetractEmailsMutation, {
  DestinationTypes,
  RETRACT_DESTINATIONS,
} from 'screens/Search/lib/hooks/useRetractEmailsMutation';

import { useSearchContext } from 'screens/Search/lib/searchContext';
import { CloseButton, Dialog, InlineTable, Scrollable, Text } from 'ui-new';
import ConditionalRender from 'ui/atoms/ConditionalRender';

interface FalseNegativeDialogProps extends ComponentProps<typeof Dialog> {
  data: SearchResultRow;
  onClose: () => void;
  visible: boolean;
}

// Constants
const DEFAULT_EXPECTED_DISPOSITION = 'MALICIOUS';
const DEFAULT_RETRACT_DESTINATION = 'RecoverableItemsDeletions';

export default function FalseNegativeDialog({ data, onClose, visible = false, ...props }: FalseNegativeDialogProps) {
  // NOTE: this stores the file input File reference, not the base64 encoded version of the contents of the file
  const [emlFile, setEmlFile] = useState<File | undefined>();
  const [expectedDisposition, setExpectedDisposition] = useState<Dispositions>(DEFAULT_EXPECTED_DISPOSITION);
  const [loadPreviewFailed, setLoadPreviewFailed] = useState(false);
  // Does the user want us to retract the email after submitting it?
  const [retractEmail, setRetractEmail] = useState(false);
  const [retractDestination, setRetractDestination] = useState<DestinationTypes>(DEFAULT_RETRACT_DESTINATION);
  const [, appendId] = useReportedAlerts();
  const [reportFn, { loading: reportFnLoading }] = useReportFalseNegativeMutation() || [null, {}]; // Jest doesn't like this hook !?!
  const [retract, { loading: retractEmailLoading }] = useRetractEmailsMutation([
    {
      clientRecipients: data.clientRecipients,
      destination: retractDestination,
      messageId: data.messageId,
    },
  ]);

  const { retractEnabled } = useSearchContext();
  const { t } = useTranslation('unisearch');

  const requireEmlUpload = useMemo(() => {
    return !retractEnabled || !!loadPreviewFailed;
  }, [retractEnabled, loadPreviewFailed]);

  async function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    let input = {
      expectedDisposition,
      messageId: data.messageId,
      postfixId: data.postfixIdent,
      recipientEmails: data.clientRecipients,
    };

    // emlBase64 should be ommitted unless we have `emlFile` to base64 encode.
    if (emlFile) {
      input = { ...input, ...{ emlBase64: await fileToBase64(emlFile) } };
    }

    try {
      await reportFn({ variables: { input } });
      // FP reporting uses alertId, but since these messages are benign alertId will be null.
      appendId(data.messageId);
    } catch (reportFNError) {
      // check Sentry for potential errors
      // eslint-disable-next-line no-console
      console.error('Error reporting FN', reportFNError);
    } finally {
      await performRetraction();
      handleClose();
    }
  }

  async function performRetraction() {
    // Retract the email if the user checks the box and retraction is enabled for the account
    if (!requireEmlUpload && retractEmail) {
      try {
        // retraction params are passed as hook arguments (messageId, clientRecipients, destination, etc)
        await retract();
      } catch (retractError) {
        // check Sentry for potential errors
        // eslint-disable-next-line no-console
        console.error('Error retracting messages', retractError);
      }
    }
  }

  function handleClose() {
    setEmlFile(undefined);
    setExpectedDisposition(DEFAULT_EXPECTED_DISPOSITION);
    setLoadPreviewFailed(false);
    setRetractDestination(DEFAULT_RETRACT_DESTINATION);
    if (typeof onClose === 'function') onClose();
  }

  function handleExpectedDispositionChange(newValue: Dispositions) {
    setExpectedDisposition(newValue);
  }

  function handleRetractDestinationChange(destination: DestinationTypes) {
    setRetractDestination(destination);
  }

  function handleEmlFileChange(file: File | undefined) {
    setEmlFile(file);
  }

  function isSubmitButtonDisabled() {
    if (reportFnLoading || retractEmailLoading) return true;
    // If retractEnabled is false then there must be an emlFile to submit
    if ((!retractEnabled || requireEmlUpload) && !emlFile) return true;
    // If retractEnabled is true, the user doesn't have to do anything else to submit
    return false;
  }

  function handleLoadPreviewFailed(value: boolean) {
    setLoadPreviewFailed(value);
  }

  // Early return
  if (!visible) return null;

  return (
    <Dialog visible={visible} {...props} onClose={handleClose}>
      <Box p>
        <Box css={{ float: 'right' }}>
          <CloseButton onPress={handleClose as ComponentProps<typeof Button>['onPress']} small />
        </Box>

        <Box pt="5">
          <Stack gap="9">
            <Stack gap="6">
              <Stack gap="2">
                <Header as="h2">{t('reportEmailFN')}</Header>
                <Text as="p" color="$gray500">
                  {t('falseNegativeReason')}
                </Text>
              </Stack>

              <Box bg="$gray100" p r>
                {!requireEmlUpload ? (
                  <ViewEmail
                    data={data}
                    onLoadPreviewFailed={handleLoadPreviewFailed}
                    retractDestination={retractDestination}
                    retractEmail={retractEmail}
                    setRetractDestination={handleRetractDestinationChange}
                    setRetractEmail={setRetractEmail}
                  />
                ) : (
                  <MissingEmail emlFile={emlFile} handleChange={handleEmlFileChange} />
                )}
              </Box>
            </Stack>

            <Cluster align="end" justify="space-between" gap="4">
              <Stack as="label" gap="1">
                <Text color="$gray700">{t('Revise disposition to')}:</Text>
                <Dropdown direction="up" onChange={handleExpectedDispositionChange} value={expectedDisposition}>
                  <Dropdown.Option value="BULK">{t('Bulk')}</Dropdown.Option>
                  <Dropdown.Option value="MALICIOUS">{t('Malicious')}</Dropdown.Option>
                  <Dropdown.Option value="SPAM">{t('Spam')}</Dropdown.Option>
                  <Dropdown.Option value="SUSPICIOUS">{t('Suspicious')}</Dropdown.Option>
                  <Dropdown.Option value="SPOOF">{t('Spoof')}</Dropdown.Option>
                </Dropdown>
              </Stack>
              <Cluster gap>
                <Button onPress={handleClose as ComponentProps<typeof Button>['onPress']}>{t('Close')}</Button>
                <Button
                  appearance="primary"
                  disabled={isSubmitButtonDisabled()}
                  onPress={handleSubmit as ComponentProps<typeof Button>['onPress']}
                >
                  {t('reportFalseNegative')}
                </Button>
              </Cluster>
            </Cluster>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

const CloudIcon = styled(CloudSVG, { color: 'gray500', width: 30 });

//
// Private Components
// -------------------------------------------------------------------------------------------------

const dropzone = css({
  border: 'none',
  width: 'auto',
});

interface DropboxProps {
  // eslint-disable-next-line no-unused-vars
  onChange(file?: File): void;
  value?: File;
}

function Dropbox({ onChange, value }: DropboxProps) {
  const [color, setColor] = useState<'$gray150' | '$gray200'>('$gray150');
  const { t } = useTranslation('unisearch');

  const handleClick = () => onChange(undefined);
  const handleDragEnter = () => setColor('$gray200');
  const handleDragLeave = () => setColor('$gray150');

  const handleDrop: DropFilesEventHandler = ([file]) => {
    setColor('$gray150');
    onChange(file);
  };

  return (
    <Box bg={color} p="5" r>
      <Dropzone
        // accept="application/vnd.sealed.eml"
        className={dropzone()}
        multiple={false}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <Cluster align="center" gap>
          <CloudIcon />
          <Text as="p" color="$gray700">
            <ConditionalRender condition={!!value}>
              <b>{t('Filename')}:</b> {value?.name}
            </ConditionalRender>
            <ConditionalRender condition={!value}>
              {/* TODO: translate */}
              Drag-drop or{' '}
              <Text color="$blue225" css={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={handleClick}>
                browse
              </Text>{' '}
              .eml file
            </ConditionalRender>
          </Text>
        </Cluster>
      </Dropzone>
    </Box>
  );
}

interface EmailDetailsProps {
  searchInfo?: SearchResultRow;
}

function EmailDetails({ searchInfo }: EmailDetailsProps) {
  const { t } = useTranslation('unisearch');

  const { clientRecipients, from, fromName, messageId, subject, ts } = searchInfo || {};

  return (
    <InlineTable css={{ width: 300 }}>
      <tbody>
        <InlineTable.Row>
          <InlineTable.Cell>
            <Text transform="uppercase" weight="semibold">
              {t('from')}
            </Text>
          </InlineTable.Cell>
          <InlineTable.Cell>
            <Text>{`${fromName || ''} <${from}>`}</Text>
          </InlineTable.Cell>
        </InlineTable.Row>

        <InlineTable.Row>
          <InlineTable.Cell colSpan={2} css={{ height: '$space$3' }} />
        </InlineTable.Row>

        <InlineTable.Row>
          <InlineTable.Cell>
            <Text transform="uppercase" weight="semibold">
              {t('subject')}
            </Text>
          </InlineTable.Cell>
          <InlineTable.Cell>
            <Text>{subject}</Text>
          </InlineTable.Cell>
        </InlineTable.Row>

        <InlineTable.Row>
          <InlineTable.Cell>
            <Text transform="uppercase" weight="semibold">
              {t('date')}
            </Text>
          </InlineTable.Cell>
          <InlineTable.Cell>
            <Text>{formatTimestamp(t('const:formats.dateLongWithTimezone'), ts)}</Text>
          </InlineTable.Cell>
        </InlineTable.Row>

        <InlineTable.Row>
          <InlineTable.Cell>
            <Text transform="uppercase" weight="semibold">
              {t('to')}
            </Text>
          </InlineTable.Cell>
          <InlineTable.Cell>
            <Text>{clientRecipients?.join(', ') || t('undisclosedRecipients')}</Text>
          </InlineTable.Cell>
        </InlineTable.Row>

        <InlineTable.Row>
          <InlineTable.Cell>
            <Text transform="uppercase" weight="semibold">
              {t('messageId')}
            </Text>
          </InlineTable.Cell>
          <InlineTable.Cell allowBreaks>
            <Text>{messageId}</Text>
          </InlineTable.Cell>
        </InlineTable.Row>
      </tbody>
    </InlineTable>
  );
}

interface FoundEmailProps {
  data: SearchResultRow;
  // eslint-disable-next-line no-unused-vars
  onLoadPreviewFailed: (value: boolean) => void;
  retractDestination: DestinationTypes;
  retractEmail: boolean;
  // eslint-disable-next-line no-unused-vars
  setRetractDestination: (value: DestinationTypes) => void;
  // eslint-disable-next-line no-unused-vars
  setRetractEmail: (value: boolean) => void;
}

function ViewEmail({
  data,
  onLoadPreviewFailed,
  retractDestination,
  retractEmail,
  setRetractDestination,
  setRetractEmail,
}: FoundEmailProps) {
  const { clientRecipients, clientUuid, messageId } = data;
  const {
    data: previews,
    error,
    loading,
  } = usePreviewsMutation({
    clientRecipients,
    clientUuid,
    messageId,
  });
  const { t } = useTranslation('unisearch');

  useEffect(() => {
    if (error && !!onLoadPreviewFailed) onLoadPreviewFailed(true);
  }, [error, onLoadPreviewFailed]);

  function handleRetractDestinationChange(destination: DestinationTypes) {
    setRetractDestination(destination);
  }

  function handleRetractEmailChange() {
    setRetractEmail(!retractEmail);
  }

  function renderPreviewsOrFallback() {
    // Early return if clientRecipients is empty
    if (clientRecipients?.length === 0)
      return (
        <Text font="sans" size="sm" stretch="ultraCondensed">
          {t('noPreview')}
        </Text>
      );

    if (loading)
      return (
        <Text font="sans" size="sm" stretch="ultraCondensed">
          {t('loading')}
        </Text>
      );

    if (error)
      return (
        <Text font="sans" size="sm" stretch="ultraCondensed">
          {t('unisearch:messagePreviewError')}
        </Text>
      );

    return (
      <Scrollable>
        {previews.map(({ preview }: PreviewType) => (
          <Stack key={messageId}>
            {preview?.image64 && (
              <img alt={data?.messageId} src={`data:image/png;base64,${preview?.image64}`} width={550} />
            )}
          </Stack>
        ))}
      </Scrollable>
    );
  }

  return (
    <Sidebar gap="5">
      <Stack justify="space-between">
        <EmailDetails searchInfo={data} />
        <Box bg="$white" p="2" r>
          <Cluster gap="4">
            <Cluster align="center" as="label" gap="2">
              <Checkbox checked={retractEmail} onChange={handleRetractEmailChange} />
              <Text color="$gray600">{t('retractEmail')}</Text>
            </Cluster>
            {retractEmail && (
              <Dropdown direction="up" onChange={handleRetractDestinationChange} value={retractDestination}>
                {RETRACT_DESTINATIONS.map((destination: DestinationTypes) => (
                  <Dropdown.Option key={`${data?.messageId}-${destination}`} value={destination}>
                    {t(destination)}
                  </Dropdown.Option>
                ))}
              </Dropdown>
            )}
          </Cluster>
        </Box>
      </Stack>
      <Box bg="$white" css={{ height: 400, width: 600 }} p="2" r>
        <Box css={{ height: '100%', position: 'relative' }}>{renderPreviewsOrFallback()}</Box>
      </Box>
    </Sidebar>
  );
}

function Header(props: ComponentProps<typeof Text>) {
  return <Text color="$orange500" size="lg" transform="uppercase" weight="semibold" {...props} />;
}

interface MissingEmailType {
  emlFile?: File;
  // eslint-disable-next-line no-unused-vars
  handleChange: (emlFile: File | undefined) => void;
}

function MissingEmail({ emlFile, handleChange }: MissingEmailType) {
  const { t } = useTranslation('unisearch');

  return (
    <Stack css={{ maxWidth: 700 }} gap="5">
      <Text as="p" color="$gray700">
        {t('missingEmailDescription')}{' '}
        <StyledLink
          color="$blue225"
          css={{ textDecoration: 'underline' }}
          href="/settings/email/retract-settings/authentication/o365"
          target="_blank"
        >
          {t('enableMessagePreview')}
        </StyledLink>
      </Text>

      <Dropbox onChange={handleChange} value={emlFile} />
    </Stack>
  );
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

const StyledLink = styled('a', {
  background: 'inherit',
  border: 'none',
  color: '$blue225',
  cursor: 'pointer',
  fontFamily: '$sans',
  fontSize: '$sm',
  fontStretch: 'ultra-condensed',
  textDecoration: 'underline',

  [`&:hover `]: {
    color: '$black',
  },
});

//
// Private functions
// -------------------------------------------------------------------------------------------------
const fileToBase64 = (file: File | undefined) => {
  if (file)
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        // reference: https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
        // response from readAsDataURL is always prepended with "data:*/*;base64,"
        if (reader.readyState !== 2) {
          reject(new Error('Reader aborted too early'));
        }
        const result = (reader.result ?? '') as string;
        // Response can include only 'data:' for empty blob, return empty string in this case.
        // Otherwise, return the string after ','
        const commaIndex = result.indexOf(',');
        const dataOffset = commaIndex > -1 ? commaIndex + 1 : result.length;
        resolve(result.substring(dataOffset));
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });

  // If no file return null
  return null;
};

function formatTimestamp(dateFormat: string, timestamp?: string) {
  if (!timestamp) return null;

  return format(parseISO(timestamp), dateFormat);
}
